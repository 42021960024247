import { AdditionalTiles } from '../models/AdditionalTile'

const accessoiresTiles: AdditionalTiles = [
  { image: '/images/services/2_accessoires/acc-01.jpg' },
  { image: '/images/services/2_accessoires/acc-02.jpg' },
  { image: '/images/services/2_accessoires/acc-03.jpg' },
  { image: '/images/services/2_accessoires/acc-04.jpg' },
]

export default accessoiresTiles
